import React from "react";

import StaticLayout from "../components/StaticLayout";

const Page = () => (
  <StaticLayout isSingle>
    <main id="main" className="site-main" role="main">
      <div className="svg-container single-post-svg svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1890 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 1925,0 0,150 -1925,0" />
        </svg>
      </div>
      <article
        id="post-22"
        className="post-22 page type-page status-publish hentry"
      >
        <header className="entry-header">
          <h1 className="entry-title">Contacteer ons</h1>{" "}
        </header>
        {/* .entry-header */}
        <div className="entry-content">
          <p>
            <em>
              <strong>Bel ons:</strong>
            </em>
          </p>
          <p>0478/ 37 10 67</p>
          <p>0479/ 66 27 82</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>
            <em>
              <strong>Mail ons: </strong>
            </em>
          </p>
          <p>
            <em>info@dancesquare.be</em>
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>
            <strong>Facebook:</strong>
          </p>
          <p>@dancesquarekv</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p />
          <p />
        </div>
        {/* .entry-content */}
        <footer className="entry-footer" />
        {/* .entry-footer */}
      </article>
      {/* #post-## */}
      <div className="svg-container single-post-svg single-svg-bottom svg-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 0 1950 150"
        >
          <g transform="translate(0,-902.36218)" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="m 898.41609,-33.21176 0.01,0 -0.005,-0.009 -0.005,0.009 z" />
          <path d="M 0,150 0,0 1950,0" />
        </svg>
      </div>
    </main>
  </StaticLayout>
);

export default Page;
